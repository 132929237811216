@import '~material-design-icons/iconfont/material-icons.css';

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

  /* z-index to display pop-ups in CKEditor */
  --ck-z-modal: 2000;
}

a {
  color: inherit;
  text-decoration: underline;
}

a.active {
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/* Add first letter in uppercase on month selector in date picker */
.MuiCalendarPicker-root .PrivatePickersFadeTransitionGroup-root div[aria-live=polite] {
  text-transform: capitalize;
}

